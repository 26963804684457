import './css/Diensten.css';

export default function Diensten() {
  return (
    <div className="body">
      <div className="hoofdTitelDiv">
        <p className="hoofdTitel">Welkom bij JoJo Clean!</p>
        <p className="hoofdTitelTekst">~</p>
        <p className="hoofdTitelTekst">Laat ons poetsen, dan kunt u stralen</p>
      </div>
      {/* Poets Div */}
      <div className="linkerDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Poetsdienst</h1>
          <p>
            Voor onze poetsdienst betaalt u GEEN extra bijdrage. Bovendien
            zorgen we voor een persoonlijk huisbezoek bij de opstart van onze
            samenwerking zodat wij u beter kunnen leren kennen en u kan aangeven
            wat uw wensen zijn.
          </p>
        </div>
      </div>

      {/* Strijkshop Div */}
      <div className="rechterDiv">
        <div className="divTekst">
          <h1>Strijkshop</h1>
          <p>
            U heeft mogelijkheid om uw strijkgoed binnen te brengen bij ons in
            het atelier. Het strijkgoed proberen we op 3 werkdagen, met
            uitzondering van drukke periodes, af te werken. U betaalt voor onze
            diensten GEEN extra bijdrage.
          </p>
        </div>

        <div className="balk2"></div>
      </div>

      {/* Ophaal Div */}
      <div className="linkerDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Ophaaldienst</h1> 
          <p style={{color: 'red'}}>(Gedurende de maand augustus werken wij uitsluitend met ophaling)</p>
          <p>
            Bij ons dienstenchequebedrijf bieden we niet alleen poets- en
            strijkdienst aan, we gaan ook nog een stapje verder. Zo halen we
            GRATIS uw strijk op en leveren we deze netjes terug af. De ophaling
            gebeurt op maandag en wordt opnieuw thuis geleverd op donderdag. Wij
            rekenen voor ophaling een 3-tal werkdagen (met uitzondering van
            drukke periodes).
            <br />
            <br />U kan een ophaling laten voorzien:
            <ul>
              <li>Per mail: info@jojoclean.be</li>
              <li>Telefonisch: 0470 07 99 97</li>
            </ul>
          </p>
        </div>
      </div>

      {/* Retouche Div */}
      <div className="rechterDiv">
        <div className="divTekst">
          <h1>Retouches & Nieuwkuis</h1>
          <p>
            Bij JoJo Clean kan u ook terecht voor kledingreparaties en
            nieuwkuis.
          </p>
        </div>

        <div className="balk2"></div>
      </div>
    </div>
  );
}

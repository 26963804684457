import "./ContactForm.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    voornaam: "",
    achternaam: "",
    email: "",
    telefoonnr: "",
    straatnaam: "",
    huisnr: "",
    bus: "",
    postcode: "",
    gemeente: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://formsubmit.co/ajax/info@jojoclean.be",
        formData
      );
      toast.success("Verzonden", { theme: "light" });
    } catch (error) {
      toast.error("Probleem met verzenden", { theme: "light" });
      console.error("Error submitting form:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        Vul hieronder uw gegevens in, eens we deze hebben ontvangen zullen wij
        zo snel mogelijk telefonisch contact met u opnemen!
      </p>
      <div className="naamDiv">
        <input
          type="text"
          id="input-group"
          className="voornaam"
          name="voornaam"
          value={formData.voornaam}
          onChange={handleChange}
          placeholder="Voornaam"
          required
        />
        <input
          type="text"
          id="input-group"
          className="achternaam"
          name="achternaam"
          value={formData.achternaam}
          onChange={handleChange}
          placeholder="Achternaam"
          required
        />
      </div>

      <div className="contactInfoDiv">
        <input
          type="email"
          id="input-group"
          className="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="E-mail"
          required
        />
        <input
          id="input-group"
          className="telefoonnr"
          name="telefoonnr"
          value={formData.telefoonnr}
          onChange={handleChange}
          placeholder="Telefoonnummer"
          required
        />
      </div>

      <div className="locatieDiv">
        <input
          id="input-group"
          className="straatnaam"
          name="straatnaam"
          value={formData.straatnaam}
          onChange={handleChange}
          placeholder="Straat"
          required
        />
        <input
          id="input-group"
          className="huisnr"
          name="huisnr"
          value={formData.huisnr}
          onChange={handleChange}
          placeholder="Huisnummer"
          required
        />
        <input
          id="input-group"
          className="bus"
          name="bus"
          value={formData.bus}
          placeholder="Bus"
          onChange={handleChange}
        />
      </div>

      <div className="gemeenteDiv">
        <input
          id="input-group"
          className="gemeente"
          name="gemeente"
          value={formData.gemeente}
          onChange={handleChange}
          placeholder="Gemeente"
          required
        />
        <input
          id="input-group"
          className="postcode"
          name="postcode"
          value={formData.postcode}
          onChange={handleChange}
          placeholder="Postcode"
          required
        />
      </div>
      <button type="submit" className="verzendKnop">
        Verzenden
      </button>
    </form>
  );
};

export default ContactForm;

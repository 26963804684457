import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineCompass,
} from "react-icons/ai";
import ContactForm from "../components/ContactForm";
import './css/Contact.css'
import { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    console.log(window.innerWidth)
  })
  return (
    <div className="body">
      <div className="hoofdTitelDiv">
        <p className="hoofdTitel">Neem contact op</p>
      </div>

      {/* Openingsuren Div */}
      <div className="linkerDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Openingsuren</h1>
          <p className="dagen">Maandag: 8u - 16u30</p>
          <p className="dagen">Dinsdag: 8u - 18u</p>
          <p className="dagen">Woensdag: 8u - 16u30</p>
          <p className="dagen">Donderdag: 8u - 16u30</p>
          <p className="dagen">Vrijdag: 8u - 18u</p>
          <p className="dagen">Zaterdag: Gesloten</p>
          <p className="dagen">Zondag: Gesloten</p>
        </div>
      </div>

      {/* Contact Div */}
      <div className="rechterDiv">
        <div className="divTekst">
          <h1>Contact</h1>
          <p>
            Bent u op zoek naar een hoogwaardige poets- en strijkdienst
            (strijkdienst kan met ophaling) dan staat ons toegewijde team klaar
            om u te woord te staan. Contact opnemen kan via info@jojoclean.be of
            telefonisch op <br />
            het nummer 0470 07 99 97. <br />
            <br />
            Kom zeker babbelen, de koffie staat klaar!
            <br />
            <br />
            <p style={{color:'red'}}>Adres pas bereikbaar vanaf 1 september</p>
          </p>

          <div className="contactDiv-group">
            <div className="contactMapDiv">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.8304752551276!2d3.838357876935852!3d50.76373356475695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3a8671a138ced%3A0x266b8cbf223a70f4!2sZarlardingestraat%2098%2C%209500%20Geraardsbergen!5e1!3m2!1snl!2sbe!4v1689897311512!5m2!1snl!2sbe" 
            title="pandLocatie"
            className="contactMap"
            width="450" 
            height="300" 
            style={{border:0}}
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"/>
            </div>
            
            <div className="contactIcon-group">
              <div className="contactIcon">
                <div ><AiOutlineCompass className="contactIconCompass" /></div> 
                <pre> </pre>{" "}
                <div className="contactIconCompassText"><p>Zarlardingestraat 98, Goeferdinge</p></div>
              </div>

              <div className="contactIcon">
                <div className="contactIconMail"><AiOutlineMail /></div>
                <pre> </pre> 
                <div className="contactIconMailText"><p>info@jojoclean.be</p></div>
              </div>

              <div className="contactIcon">
                <div className="contactIconPhone"><AiOutlinePhone /></div>
                <pre> </pre> 
                <div className="contactIconPhoneText"><p>0470 07 99 97</p></div>
              </div>
            </div>
          </div>
        </div>
        <div className="balk2"></div>
      </div>

      {/* Openingsuren Div */}
      <div className="aanvraagDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Aanvraag poetshulp</h1>

          <div>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Diensten from './pages/Diensten';
import Over from './pages/Over';
import Dienstencheques from './pages/Dienstencheques';
import Contact from './pages/Contact';
import Vacatures from './pages/Vacatures'
import Header from './components/Header';
import { ToastContainer } from "react-toastify";

import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    
    <HashRouter>
      <ToastContainer/>
      <Header/>
      
      <Routes>
      
        <Route path="/" element={<Diensten/>}></Route>

        <Route path="/over" element={<Over/>} />

        <Route path="/dienstencheques" element={<Dienstencheques/>} />

        <Route path="/contact" element={<Contact/>} />

        <Route path='/vacatures' element={<Vacatures/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

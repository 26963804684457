export default function Over() {
  return (
    <div className="body">
      <div className="hoofdTitelDiv">
        <p className="hoofdTitel">Bedankt!</p>
        <p className="hoofdTitelTekst">~</p>
        <p className="hoofdTitelTekst">
          Je klikte op onze vacatures.
          <br />
          Wij willen je alvast bedanken voor je interesse in JoJo Clean.{" "}
        </p>
        <br/><br/>
      </div>

      <div className="linkerDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Solliciteren</h1>
          <p>
            Wil je graag deel uitmaken van ons team? Welkom!
            <br />
            <br />
            Als jij:
          </p>

          <ul className="solliciterenList">
            <li>
              een passie hebt voor schoonmaken en streeft naar een nette
              omgeving
            </li>
            <li>oog hebt voor detail</li>
            <li>gemotiveerd en betrouwbaar bent ...</li>
          </ul>
          <br />

          <p>
            ... dan willen we graag van je horen! Solliciteren is eenvoudig.
            Stuur je CV en korte motivatie door naar info@jojoclean.be. Wij
            kijken er alvast naar uit jou te mogen ontmoeten.
          </p>
        </div>
      </div>
    </div>
  );
}

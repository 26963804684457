import "./Header.css";
import React, { useState, useEffect } from "react";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import logo from "../images/jojoclean.png";
import Hamburger from "hamburger-react";

export default function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setOpen] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
    setOpen(!isOpen);
  };

  const hideNav = () => {
    setToggleMenu(false);
    setOpen(false);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <header>
      <div id="logo" className="w-max absolute">
        <img id="logoImage" src={logo} alt="" />
      </div>
      <div id="mainNavigation">
        <div className="navMenuMainBox">
          <div className="navMenuSocialIcons">
            <div className="navMenuIcon">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/jojo_clean_/"
              >
                <BsInstagram size={30} color='black' />
              </a>
            </div>
            <div className="navMenuIcon">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100094897694553"
              >
                <BsFacebook size={30} color="black" />
              </a>
            </div>
          </div>
          {(toggleMenu || screenWidth > 768) && (
            <ul className={`navMenu ${toggleMenu ? "open" : ""}`}>
              <li className="menuItem">
                <Link to="/" className="menuItemLink" onClick={hideNav}>
                  Onze Diensten
                </Link>
              </li>
              <li className="menuItem">
                <Link to="/over" className="menuItemLink" onClick={hideNav}>
                  Over Ons
                </Link>
              </li>
              <li className="menuItem">
                <Link
                  to="/dienstencheques"
                  className="menuItemLink"
                  onClick={hideNav}
                >
                  Dienstencheques
                </Link>
              </li>
              <li className="menuItem">
                <Link to="/contact" className="menuItemLink" onClick={hideNav}>
                  Contact
                </Link>
              </li>
              <li className="menuItem">
                <Link to="/vacatures" className="menuItemLink" onClick={hideNav}>
                  Vacatures
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className="hamburger" onClick={toggleNav}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
      </div>
    </header>
  );
}

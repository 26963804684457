import johanImg from "../images/johan_van_holder.jpg";
import './css/Over.css';

export default function Over() {
  return (
    <div className="body">
      <div className="hoofdTitelDiv">
        <p className="hoofdTitel">Leer ons kennen</p>
      </div>

      <div className="mainDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Jojo Clean</h1>
          <p>
            Welkom bij JoJo Clean, jouw nieuwe schoonmaakpartner.
            <br />
            Kom zeker kennismaken met ons en laat u informeren over onze poets-
            en strijkdiensten. <br />
            De persoonlijke aanpak en de begeleiding van onze medewerkers staan
            centraal.
            <br />
            We zorgen voor een grondige opleiding en blijven hen ondersteunen
            tijdens hun werk bij u thuis.
          </p>
        </div>
      </div>

      <div className="functieDiv">
        <div className="left-side">
          <div className="divFunctieTekst">
            <h1>Zaakvoerder</h1>
          </div>
        </div>

        <div className="imagePersoon">
          <img src={johanImg} alt="" />
          <p className="naamPersoon">Johan Van Holder</p>
        </div>
      </div>
    </div>
  );
}

import "../pages/css/Dienstencheques.css";

export default function Dienstencheques() {
  return (
    <div className="body">
      <div className="hoofdTitelDiv">
        <p className="hoofdTitel">Dienstencheques</p>
      </div>
      <div className="aanvraagDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Aanvraag dienstencheques </h1>
          <ol>
            <li className="aanvraagList">
              U kan zich online inschrijven aan de hand van{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://dienstencheques.vlaanderen.be/burger/bestelling/alles-weten/hoe-bestellen"
              >
                deze link
              </a>
              .
            </li>
            <li className="aanvraagList">
              Vervolgens klikt u op 'beveiligde zone'.
            </li>
            <li className="aanvraagList">
              Geef aan of u al dan niet een account heeft.
            </li>
            <li className="aanvraagList">
              Volg daarna de aangegeven stappen.
            </li>
          </ol>
        </div>
      </div>

      <div className="rechterDiv">
        <div className="divTekst">
          <h1>Hulp voor 7,20 euro!</h1>
          <p>
            De kostprijs van een dienstencheque is (momenteel) 9 euro. Hiervan
            is 30% fiscaal aftrekbaar. Als klant betaalt u dus nog een bedrag
            van 7,20 euro.
          </p>
        </div>

        <div className="balk2"></div>
      </div>

      <div className="linkerDiv">
        <div className="balk1"></div>
        <div className="divTekst">
          <h1>Diensten</h1>
          <p>
            Met een dienstencheque kan je van onderstaande diensten gebruik
            maken:
            <ul>
              <li>Schoonmaken van uw woning</li>
              <li>Ramen poetsen</li>
              <li>Wassen</li>
              <li>Strijken</li>
              <li>Occasionele kleine verstelwerken</li>
              <li>Bereiden van maaltijden</li>
              <li>Boodschappen doen</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}
